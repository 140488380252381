*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: Lato, sans-serif;
  line-height: 1.5;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

html {
  --lightningcss-light: ;
  --lightningcss-dark: initial;
  color-scheme: dark;
  --color-notice: #0015ff;
  --color-notice-bg: #fff;
  --color-warning: #eb8900;
  --color-warning-bg: #fff5e0;
  --color-success: #12a112;
  --color-success-bg: #ecfeec;
  --color-error: #ff0040;
  --color-error-bg: #feecef;
  --shadow-color: 250deg 35% 11%;
  --shadow-elevation-low: 0px .6px .6px hsl(var(--shadow-color) / .26), 0px .8px .8px -1.6px hsl(var(--shadow-color) / .22), 0px 1.8px 1.8px -3.2px hsl(var(--shadow-color) / .19);
  --shadow-elevation-medium: 0px .3px .6px hsl(var(--shadow-color) / .2), 0px .7px 1.4px -1.1px hsl(var(--shadow-color) / .19), 0px 1.5px 3.7px -2.1px hsl(var(--shadow-color) / .18), .1px 4px 9.2px -3.2px hsl(var(--shadow-color) / .17), .2px 6px 15px -2.3px hsl(var(--shadow-color) / .16);
  --shadow-elevation-high: 0px .6px .6px hsl(var(--shadow-color) / .26), 0px 1.8px 1.8px -.5px hsl(var(--shadow-color) / .24), 0px 3.2px 3.3px -.9px hsl(var(--shadow-color) / .23), .1px 5.4px 5.5px -1.4px hsl(var(--shadow-color) / .21), .1px 9.1px 9.3px -1.8px hsl(var(--shadow-color) / .2), .2px 14.7px 15px -2.3px hsl(var(--shadow-color) / .19), .3px 22.8px 23.3px -2.7px hsl(var(--shadow-color) / .17), .4px 34.2px 34.9px -3.2px hsl(var(--shadow-color) / .16);
}

body {
  background-image: conic-gradient(from 270deg at 50% -20%, #080812 50%, #251f47, #332d53, #251f47, #080812);
  background-attachment: fixed;
}
/*# sourceMappingURL=index.f75a1bf7.css.map */
